import axios from 'axios';

// Define state
const state = {
  id: 0,
  username: '',
  token: localStorage.getItem('userToken') || '',
};

// Define mutations
const mutations = {
  SET_USER(state, { id, brugernavn }) {
    state.id = id;
    state.username = brugernavn;

    // Save user data to local storage
    localStorage.setItem('userId', id);
    localStorage.setItem('username', brugernavn);
  },
  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem('userToken', token);
  },
  CLEAR_USER(state) {
    state.id = null;
    state.username = '';
    state.token = '';


    // Clear user data from local storage
    localStorage.removeItem('userId');
    localStorage.removeItem('brugernavn');
    localStorage.removeItem('username');
    localStorage.removeItem('userToken');
  },
};

// Define actions
const actions = {
  async login({ commit }, { username, password }) {
    try {
      const response = await axios.post('https://api.datafangst.dk/api/auth/login', { username, password });
      console.log(response.data);
      const { id, token, brugernavn } = response.data;
      commit('SET_USER', { id, brugernavn });
      commit('SET_TOKEN', token);
      
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  },
  logout({ commit }) {
    commit('CLEAR_USER');
  },
};

const getters = {
  isLoggedIn: state => !!state.token,
};

// Export the module
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};