import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        next();
      } else {
        next('/login'); // Redirect to login if user token doesn't exist
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "timeregistration" */ '../views/LoginView.vue')
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import(/* webpackChunkName: "about" */ '../views/LabelView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
