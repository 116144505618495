import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { createI18n, useI18n } from 'vue-i18n'
import { en, sv, da } from 'vuetify/locale'
import { loadFonts } from './plugins/webfontloader'


loadFonts();
await store.dispatch('labels/fetchLabelOptionsAndConfig');

const messages = {
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}',
      },
    },
  },
  sv: {
    $vuetify: {
      ...sv,
      dataIterator: {
        rowsPerPageText: 'Element per sida:',
        pageText: '{0}-{1} av {2}',
      },
    },
  },
  da: {
    $vuetify: {
      ...da,
      dataIterator: {
        rowsPerPageText: 'Elementer pr side:',
        pageText: '{0}-{1} af {2}',
      },
    },
  },
}

const i18n = createI18n({
  legacy: false,
  locale: 'da',
  fallbackLocale: 'en',
  messages,
})


createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
