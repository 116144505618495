import { createStore } from 'vuex';

import auth from './auth';
import users from './users';
import labels from './labels';


export default createStore({
  modules: {
    auth,
    users,
    labels,
  }
});