<template>
  <div id="app">
    <template v-if="isLoggedIn">
      <AuthenticatedLayout></AuthenticatedLayout>
    </template>
    <template v-else>
      <LoginLayout>
        <!-- <router-view></router-view> -->
      </LoginLayout>
    </template>
  </div>
</template>

<script>
import AuthenticatedLayout from './components/AuthenticatedLayout.vue';
import LoginLayout from './components/LoginLayout.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AuthenticatedLayout,
    LoginLayout
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    user() {
      // Brugerdata i localstorage, hvis logget ind. Ellers fallback til No Name
      return localStorage.getItem('realname') || 'No Name';
    }
  }
};
</script>