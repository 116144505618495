const state = {
// users state
};

const mutations = {
// users mutations
};

const actions = {
// users actions
};

const getters = {
// users getters
};
    
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};